import React, { useEffect, useState } from 'react';

const OneTrustPrivacyConsent = () => {
  const html = `
  <!-- CookiePro Cookies Consent Notice start for swipr.cc -->
<script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="9a6df4ab-705a-4d93-b614-64ea9a01ddbb-test" ></script>
<script type="text/javascript">
function OptanonWrapper() { }
</script>
<!-- CookiePro Cookies Consent Notice end for swipr.cc -->`;
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const PrivacyConsent = () => {
  const [close, setClose] = useState(true);

  const onConsent = (consentStatus) => {
    setClose(true);
    localStorage.setItem('privacy-consent', consentStatus);
  };

  useEffect(() => {
    const hasConsent =
      typeof window !== 'undefined' &&
      localStorage.getItem('privacy-consent') !== null;
    setClose(hasConsent);
  }, []);

  if (close) {
    return null;
  }

  return (
    <div className="privacy-consent">
      <div className="content">
        <div className="word">
          We value your privacy. We and our partners use trackers to measure the
          audience of our website and to provide you with offers and improve our
          own Swipr marketing operations.
        </div>
        <div className="buttons-container">
          <div className="buttons" onClick={() => onConsent(true)}>
            <div className="button">
              <a href="#">DECLINE ALL</a>
            </div>
            <div className="button" onClick={() => onConsent(false)}>
              <a href="#">ACCEPT ALL</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTrustPrivacyConsent;
